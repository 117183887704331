
import { Options, Vue } from 'vue-class-component';
import { LoginServices } from '@/services/LoginServices';
import { StorageServices } from '@/services/StorageServices';
import { AdminClient } from '@/services/Services';
import { Watch } from 'vue-property-decorator';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class Profile extends Vue {

    selectedLanguage: string = "";
    availableLanguages: OM.TextIdentifier[] = [];

    loggedUser: OM.LoggedUserVM = new OM.LoggedUserVM();
    isGadgetDisponible: boolean = false;

    created() {
        this.loggedUser = StorageServices.getLoggedUser();
        this.selectedLanguage = this.loggedUser.preferredCulture;
        this.availableLanguages = this.$localizationService.getEnabledLanguageList();
        AdminClient.getGadgetStatus()
        .then( x => {
            this.isGadgetDisponible = x;
        })

    } 

    get motoguzziSiteLinkLocalized() {
        var ris = "https://motoguzzi.com/it_IT/open-house/";
        if(store.state.selectedLanguage == "en-US")
            ris = "https://www.motoguzzi.com/en_EN/open-house/"
        
        return ris;
    }

    @Watch('selectedLanguage')
    updateLanguage() {
        this.setLanguage();
    }

    setLanguage(){
        var loggedUser = store.state.loggedUser;
        loggedUser.preferredCulture = this.selectedLanguage;
        
        store.state.selectedLanguage = this.selectedLanguage;

        StorageServices.setLoggedUser(loggedUser);
        store.state.loggedUser = loggedUser;
    }

    logout() {
        LoginServices.logout();
    }

}
